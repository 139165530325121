.bill_info_card {
    max-width: 500px;
}

.card_content_label {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 3px;
}

@media only screen and (max-width: 480px) {}
.login_page {
  position: relative;
  background: url("/public/Images/login-background-color.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.login_page_background {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: url("/public/Images/login-cactus.jpg") no-repeat right;
  background-size: contain;
}

.authen_wrapper {
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin-top: 40px;
  width: 100px;
  height: 100px;
}

.name {
  font-size: 60px;
  font-weight: 900;
}

.form {
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
}

.form_input {
  border-width: 0 !important;
  padding: 10px 7px;
  /* width: 50vw; */
  /* max-width: 300px; */
  width: 300px;
}

.icon {
  font-size: 18px;
  margin-right: 10px;
}

.line {
  width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 5px 5%;
}

.authen_button {
  width: 310px;
  margin-top: 30px;
  padding: 8px 0;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  height: auto;
}
.customer_info_card {
    max-width: 500px;
}

.org_list_card {
    min-width: 400px;
    max-width: 700px;
}

.card_content_label {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 3px;
}

.delete_customer_button {
    margin: 20px 0 0 20px;
    color: #141D49;
    font-weight: 700;
    cursor: pointer;
    width: max-content;
}

.box {
    display: inline-block;
    background-color: #EFF3F7;
    /* Màu nền */
    padding: 10px 20px;
    /* Khoảng cách nội dung */
    border-radius: 10px;
    /* Bo góc */
    color: #1F2A44;
    /* Màu chữ */
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
}

@media only screen and (max-width: 480px) {}
.customer_info_card {
    max-width: 500px;
}

.org_list_card {
    min-width: 400px;
    max-width: 700px;
}

.card_content_label {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 3px;
}

.delete_customer_button {
    margin: 20px 0 0 20px;
    color: #141D49;
    font-weight: 700;
    cursor: pointer;
    width: max-content;
}

@media only screen and (max-width: 480px) {}